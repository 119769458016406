
const je = require('json-encrypt')
let user_permissions = window.localStorage.getItem("user_permissions");
user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));

var modules = [{
  _name: 'CSidebarNavItem',
  name: 'Inicio',
  to: '/inicio/inicio',
  icon: 'cilHome',
}];


if(user_permissions.indexOf('BoxList') > - 1 || user_permissions.indexOf('OtherList') > - 1  || user_permissions.indexOf('ExtraordinaryIncomeList') > - 1 || user_permissions.indexOf('ExtraordinaryExpenseList') > - 1 ){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('BoxList') > -1) {
    item.push({ name: 'Listar', to: '/caja/listar' });
  }
  if (user_permissions.indexOf('BoxAdd') > -1) {
    item.push({ name: 'Caja', to: '/caja/nuevo' });
  }
  if (user_permissions.indexOf('ExtraordinaryExpenseList') > -1) {
    item.push({ name: 'Egresos', to: '/egreso-extraordinario/listar' });
  }
  if (user_permissions.indexOf('OtherList') > -1) {
    item.push({ name: 'Otros', to: '/otros/listar' });
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Caja',
    icon: 'cilCash',
    items: item
  }
  modules.push(TypeUser);
}




if(user_permissions.indexOf('PartnerList') > -1  ){
    let TypeUser = {
      _name: 'CSidebarNavItem',
      name: 'Socio',
      to: '/socio/listar',
      icon: 'cil-people',
    }
    modules.push(TypeUser);
}

if(user_permissions.indexOf('LoanSimulatorAdd') > -1  ){
  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Simulador de Prestamo',
    to: '/simulador-prestamo/nuevo',
    icon: 'cilDollar',
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('LoanList') > - 1 || user_permissions.indexOf('LoanPaymentList') > - 1
 || user_permissions.indexOf('PrepaidList') > - 1 ){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('LoanList') > -1) {
    item.push({ name: 'Prestamos', to: '/prestamo/listar' });
  }
  if (user_permissions.indexOf('LoanPaymentList') > -1) {
    item.push({ name: 'Pagos', to: '/pago-prestamo/listar' });
  }
  if (user_permissions.indexOf('PrepaidList') > -1) {
    item.push({ name: 'Pago Anticipado', to: '/pago-anticipado/listar' });
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Prestamo',
    icon: 'cilDollar',
    items: item
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('ContributionList') > - 1 || user_permissions.indexOf('AccumulatedContributionList') > - 1 ){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('ContributionList') > -1) {
    item.push({ name: 'Aportes', to: '/aporte/listar' });
  }
  if (user_permissions.indexOf('AccumulatedContributionList') > -1) {
    item.push({ name: 'Aporte Acumulado', to: '/aporte-acumulado/listar' });
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Aportes',
    icon: 'cilLayers',
    items: item
  }
  modules.push(TypeUser);
}



if(user_permissions.indexOf('SettlementContributionList') > -1  ){
  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Liquidación de Aporte',
    to: '/liquidacion-aporte/listar',
    icon: 'cilTask',
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('BurialList') > -1  ){
  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Sepelio',
    to: '/sepelio/listar',
    icon: 'cilList',
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('CheckList') > -1  ){
  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Desembolso',
    to: '/desembolso/listar',
    icon: 'cilGrid',
  }
  modules.push(TypeUser);
}



if(user_permissions.indexOf('ReportContributionList') > -1 || user_permissions.indexOf('ReportLoanList') > -1
|| user_permissions.indexOf('ReportLoanPaymentList') > -1 || user_permissions.indexOf('ReportLoanPaymentDetailList') > -1
|| user_permissions.indexOf('ReportContributionDetailList') > -1 || user_permissions.indexOf('ReportMovementList') > -1  
|| user_permissions.indexOf('ReportPaymentStatusList') > -1

){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('ReportLoanList') > -1) {
    item.push({ name: 'Prestamo', to: '/reporte/prestamos' });
  }
  if (user_permissions.indexOf('ReportLoanPaymentList') > -1) {
    item.push({ name: 'Pagos', to: '/reporte/pago-prestamos' });
  }
  if (user_permissions.indexOf('ReportLoanPaymentDetailList') > -1) {
    item.push({ name: 'Pagos Detallado', to: '/reporte/pago-prestamos-detallado' });
  }
  if (user_permissions.indexOf('ReportContributionList') > -1) {
    item.push({ name: 'Aportes', to: '/reporte/aportes' });
  }
  if (user_permissions.indexOf('ReportContributionDetailList') > -1) {
    item.push({ name: 'Aportes Detallado', to: '/reporte/aportes-detallado' });
  }
  if (user_permissions.indexOf('ReportMovementList') > -1) {
    item.push({ name: 'Movimiento', to: '/reporte/movimiento' });
  }
  if (user_permissions.indexOf('ReportPaymentStatusList') > -1) {
    item.push({ name: 'Estado de Pagos', to: '/reporte/estado-pago' });
  }
  
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Reporte',
    icon: 'cilFile',
    items: item
  }
  modules.push(TypeUser);
}



if(user_permissions.indexOf('UserList') > -1 || user_permissions.indexOf('UserTypeList') > -1  || user_permissions.indexOf('VoucherList') > -1 || user_permissions.indexOf('BusinessList') > -1 
|| user_permissions.indexOf('FinancialData') > -1 || user_permissions.indexOf('AuditList') > -1  || user_permissions.indexOf('ExchangeRateList') > -1){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('UserList') > -1) {
    item.push({ name: 'Usuario', to: '/usuario/listar' });
  }
  if (user_permissions.indexOf('UserTypeList') > -1) {
    item.push({ name: 'Tipo de Usuario', to: '/tipo-usuario/listar'});
  }
  if (user_permissions.indexOf('VoucherList') > -1) {
    item.push({ name: 'Comprobante', to: '/comprobante/listar'});
  }
  if (user_permissions.indexOf('FinancialDataList') > -1) {
    item.push({ name: 'Dato Financiero', to: '/dato-financiero/listar'});
  }
  if (user_permissions.indexOf('AuditList') > -1) {
    item.push({ name: 'Auditoria', to: '/auditoria/listar'});
  }
  if (user_permissions.indexOf('BusinessList') > -1) {
    item.push({name: 'Empresa',to: '/empresa/editar'});
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Mantenimiento',
    icon: 'cilCog',
    items: item
  }
  modules.push(TypeUser);
}

export default [
  {
    _name: 'CSidebarNav',
    _children: modules
  }
]